"use strict";
(function( $, window ) {
  var _defaults = {
    row : 4, // tiles in x axis
    column : 4, // tiles in y axis
    activeTileClass: 'tileActive'
  };

  function extractActiveTile( $tiles ) {
    if( $tiles.length > 0 ) {
      var string_tiles = $tiles.split('|');
      var tiles = [];
      string_tiles.forEach(function(tile) {
        var raw_tile = tile.split(',');
        tiles.push({
          x: parseInt(raw_tile[0]),
          y: parseInt(raw_tile[1])
        });
      } );
      return tiles;
    }else{
      return;
    }
  }

  $.fn.splitInTiles = function( options ) {
    var opt = $.extend( {}, _defaults, options );
    return this.each(function() {
      var $container = $(this),
          row = $container.attr('data-row') || opt.row,
          column = $container.attr('data-column') || opt.column,
          canvas = document.createElement('canvas'),
          ctx = canvas.getContext('2d'),
          maxWidth = 90/column,
          reader = new FileReader(),
          image = new Image(),
          imageUrl = $container.attr('data-image-url') || opt.image,
          onClickTile = opt.onClickTile,
          activeTileClass = opt.activeTileClass,
          activeTiles = extractActiveTile($container.attr('data-active-tile')) || opt.activeTiles;
          if(!imageUrl){
            return;
          }

          if(typeof imageUrl === 'string'){
            var request = new XMLHttpRequest();
            request.open('GET', imageUrl, true);
            request.responseType = 'blob';
            request.onload = function() {
              if (request.status === 200) {
                var blob = request.response;
                reader.readAsDataURL(blob);
              }else{
                return;
              }
            };
            request.send();
          }else{
            reader.readAsDataURL(imageUrl);
          }
          $container.html('Loading...');
          if($container.hasClass('load-image')){
            return;
          }
          reader.onload = function(event){
            $container.html('');
            $container.addClass('load-image');
            image.src = event.target.result;
            image.onload = function(){
              var tileWidth = Math.floor(image.width/column),
                  tileHeight = Math.floor(image.height/row);
              canvas.width = tileWidth;
              canvas.height = tileHeight;
              for(var tilesY = 0; tilesY < row; tilesY++){
                var tilesColumnDiv = document.createElement('div');
                tilesColumnDiv.style.cssText = `margin-bottom: 3px; line-height: 2px;`;
                tilesColumnDiv.classList.add(`y-${tilesY}`)
                for(var tilesX = 0; tilesX < column; tilesX++){
                  var x = (-tileWidth*tilesX) % (Math.floor(tileWidth*column)),
                      y = (-tileHeight*tilesY) % (Math.floor(tileHeight*row));
                  x = tilesX === 0 ? x : (-tileWidth*tilesX);
                  y = tilesY === 0 ? y : (-tileHeight*tilesY);

                  ctx.drawImage(this, x, y, Math.floor(tileWidth*column), Math.floor(tileHeight*row));
                  var slicedImage = document.createElement('img');
                  slicedImage.src = canvas.toDataURL();
                  slicedImage.className = "img-fluid";
                  slicedImage.style.cssText = `max-width:${maxWidth}%; border: 2px solid white`;
                  slicedImage.setAttribute('data-x', tilesX);
                  slicedImage.setAttribute('data-y', tilesY);
                  if(activeTiles){
                    if(activeTiles.find(tile => tile.x === tilesX && tile.y === tilesY)){
                      slicedImage.classList.add(activeTileClass);
                    }
                  }
                  if ($.isFunction(onClickTile)) {
                    slicedImage.onclick = function() {
                      var result = {
                        container: $container,
                        tile: this,
                        x: tilesX,
                        y: tilesY
                      };
                      onClickTile(result);
                    };
                  }
                  tilesColumnDiv.appendChild(slicedImage);
                }
                if($container.find(`.y-${tilesY}`).length === 0){
                  $container[0].appendChild(tilesColumnDiv);
                }
              }
              $container.removeClass('load-image');
            }
          };
    });
  }
}( jQuery, window ));