
"use strict";
$(document).ready(function(){
  function addRowArray(ev) {
      var $nearest_row, $new_row, $remove_button;
      ev.preventDefault();
      $nearest_row = $(this).prev();
      $nearest_row.find('.text-array__remove').show();
      $new_row = $nearest_row.clone();
      $new_row.find('input').val("");
      $remove_button = $new_row.find('.text-array__remove');
      $remove_button.on('click',removeRowArray);
      $new_row.insertBefore(this);
    };

    function removeRowArray(ev) {
      ev.preventDefault();
      this.closest('.input-group').remove();
    };

    $('.text-array__add').on('click', addRowArray);
    $('.text-array__remove').on('click', removeRowArray);

    window.addRowArray = addRowArray;
    window.removeRowArray = removeRowArray;
});