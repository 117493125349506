"use strict";
$(document).ready(function(){
  initSummernote();
  $(document).on('cocoon:after-insert', function(){
    initSummernote();
  });

  function initSummernote(){
    $('.summernote').summernote({
      height: '200px',
      toolbar: [
        [ 'font', [ 'bold', 'italic', 'underline', 'clear'] ],
        [ 'insert', [ 'link'] ],
        [ 'view', [ 'undo', 'redo', 'fullscreen', 'codeview', 'help' ] ]
      ]
    });
  }
});