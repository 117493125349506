$(document).ready(function () {
  function slickCovers() {
    $(".task-cover-slider").slick({
      // normal options...
      infinite: true,
      autoplay: true,
      dots: true,
      lazyLoad: "progresive",
    });
  }
  slickCovers();

  document.addEventListener("places-loaded", function () {
    slickCovers();
    $(".charts").each(function () {
      var chartScript = $(this).parent().find("script");
      if (chartScript) {
        var chartData = chartScript.html();
        var script = document.createElement("script");
        script.textContent = chartData;
        document.body.appendChild(script);
        console.log();
      }
    });
  });
});
